var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ndvi-upsell rounded-lg"},[_c('v-row',{staticClass:"ndvi-content align-center",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"4","lg":"6"}},[_c('div',{staticClass:"ndvi-content__label"},[_vm._v(" "+_vm._s(_vm.$t('Plot.NdviUpsell.message'))+" ")])]),_c('v-col',{attrs:{"cols":"12","md":"8","lg":"6"}},[_c('v-container',{staticClass:"d-flex ndvi-content__actions"},[_c('v-btn',{staticClass:"white--text text-none actions-buttons dark-background",attrs:{"text":"","data-v-step":"whatis-ndvi"},on:{"click":function($event){return _vm.handleButtonClick(
              'whatIsNDVI',
              'https://digifarmz.com/o-que-e-ndvi/'
            )}}},[_c('span',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(_vm.$t('Plot.NdviUpsell.what_is'))+" ")]),_c('font-awesome-icon',{staticClass:"icons",attrs:{"icon":"map"}})],1),_c('v-btn',{staticClass:"white--text text-none actions-buttons button-background",attrs:{"text":"","data-v-step":"contract-ndvi"},on:{"click":function($event){_vm.handleButtonClick(
              'contractNDVI',
              'https://wa.me/5551994567668?text=',
              _vm.$t('Plot.NdviUpsell.contract_ndvi')
            )}}},[_c('span',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(_vm.$t('Plot.NdviUpsell.contract'))+" ")]),_c('font-awesome-icon',{staticClass:"icons",attrs:{"icon":"phone"}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }