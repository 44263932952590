<template>
  <div>
    <df-page-header :title="field.nome" :subtitle="$t('Plot.Infos.title')">
      <template #infos>
        <div class="df-flex-sm header__infos">
          <v-icon small color="#1A2B46">mdi-vector-square</v-icon>
          <div class="d-flex df-flex-col">
            <h3 class="infos__title">
              {{ $t('Plot.Infos.area').toUpperCase() }}
            </h3>
            <p class="infos__text">
              {{ $t('Plot.Infos.sown') }} - {{ plantingArea
              }}{{ $t('Plot.Infos.hectare') }} / {{ $t('Plot.Infos.total') }} -
              {{ field.area }}{{ $t('Plot.Infos.hectare') }}
            </p>
          </div>
        </div>
      </template>
      <template #actions>
        <df-button icon="seedling" @click="openPlantingForm">
          {{ $t('Plot.Buttons.new_sowing') }}
        </df-button>
        <df-button icon="seedling" @click="openFormRegisterNote">
          {{ $t('Plot.Buttons.new_note') }}
        </df-button>
      </template>
    </df-page-header>
    <modal-semeadura
      v-if="openPlantingModal"
      :dialog="openPlantingModal"
      @reload-sowing-list="shootSowingList"
      @close="closePlantingForm"
    />
    <modal-note
      v-if="noteRegisterOpen"
      :dialog="noteRegisterOpen"
      @close="closeModalNote"
    />
  </div>
</template>

<script>
import DfButton from '@/lib/components/Button/DfButton.vue'
import DfPageHeader from '@/lib/components/Header/DfPageHeader.vue'
import ModalNote from '@/modules/notes/components/Modals/ModalNote.vue'
import ModalSemeadura from '@/pages/semeaduras/ModalSemeadura.vue'
import { logEvent, events } from '@/services/analytics'
import { mapActions } from 'vuex'

export default {
  name: 'FieldHeader',

  components: {
    DfButton,
    DfPageHeader,
    ModalNote,
    ModalSemeadura,
  },

  props: {
    field: {
      required: true,
      type: Object,
    },
    plantingArea: {
      default: 0,
      type: Number,
    },
  },

  data() {
    return {
      noteRegisterOpen: false,
      openPlantingModal: false,
    }
  },

  mounted() {
    this.fetchNoteCategories()
  },

  methods: {
    ...mapActions('semeaduras', ['loadSowingsFilter']),
    ...mapActions('notes', ['fetchNoteCategories']),
    closeModalNote() {
      this.noteRegisterOpen = false
    },
    closePlantingForm() {
      this.openPlantingModal = false
    },
    openFormRegisterNote() {
      logEvent(events.plotModule.createNote)
      this.noteRegisterOpen = true
    },
    openPlantingForm() {
      logEvent(events.plotModule.createSeedling)
      this.openPlantingModal = true
    },
    async shootSowingList() {
      try {
        await this.loadSowingsFilter()
      } catch (error) {
        console.error(error)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.header__infos {
  flex: 0 0 auto;
  align-items: start;

  @include d(m) {
    display: none;
  }
  .infos__title {
    color: #1a2b46;
    font-family: 'Rubik';
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    text-transform: uppercase;
  }
  .infos__text {
    color: #5b6459;
    font-family: 'Rubik';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
}
</style>
