<template>
  <card-weather class="weather-tab" />
</template>

<script>
import CardWeather from '@/components/Weather/CardWeather.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'WeatherTab',

  components: {
    CardWeather,
  },

  computed: {
    ...mapGetters('farms', ['currentFarmId']),
  },
}
</script>

<style lang="scss" scoped>
::v-deep .v-card {
  border: none !important;
  box-shadow: none !important;
}
</style>
