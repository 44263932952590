<template>
  <div class="ndvi-upsell rounded-lg">
    <v-row no-gutters class="ndvi-content align-center">
      <v-col cols="12" md="4" lg="6">
        <div class="ndvi-content__label">
          {{ $t('Plot.NdviUpsell.message') }}
        </div>
      </v-col>
      <v-col cols="12" md="8" lg="6">
        <v-container class="d-flex ndvi-content__actions">
          <v-btn
            class="white--text text-none actions-buttons dark-background"
            text
            @click="
              handleButtonClick(
                'whatIsNDVI',
                'https://digifarmz.com/o-que-e-ndvi/'
              )
            "
            data-v-step="whatis-ndvi"
          >
            <span class="mr-2"> {{ $t('Plot.NdviUpsell.what_is') }} </span>
            <font-awesome-icon class="icons" icon="map" />
          </v-btn>
          <v-btn
            class="white--text text-none actions-buttons button-background"
            text
            @click="
              handleButtonClick(
                'contractNDVI',
                'https://wa.me/5551994567668?text=',
                $t('Plot.NdviUpsell.contract_ndvi')
              )
            "
            data-v-step="contract-ndvi"
          >
            <span class="mr-2"> {{ $t('Plot.NdviUpsell.contract') }} </span>
            <font-awesome-icon class="icons" icon="phone" />
          </v-btn>
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { logEvent, events } from '@/services/analytics'

export default {
  name: 'NdviUpsell',

  methods: {
    handleButtonClick(event, link, message = null) {
      this.registerEvent(event)
      this.redirectLink(link, message)
    },
    registerEvent(event) {
      logEvent(events.plotModule + event)
    },
    redirectLink(link, message) {
      let translatedMessage = ''
      if (message) {
        translatedMessage = '+' + message.replaceAll(' ', '+')
      }
      window.open(link + translatedMessage, '_blank')
    },
  },
}
</script>

<style lang="scss" scoped>
.ndvi-upsell {
  background: #00000066;
  padding: 12px;

  .ndvi-content {
    justify-content: space-between;

    .ndvi-content__label {
      font-family: 'Source Sans Pro';
      font-size: 14px;
      font-weight: 700;
      line-height: 18px;
      letter-spacing: 0em;
      color: #ffffff;
      padding: 0px 8px;
    }

    .ndvi-content__actions {
      padding: 0px;
      gap: 12px;
      justify-content: end;

      @media (max-width: 959px) {
        justify-content: center;
        margin-top: 12px;
      }

      @media (max-width: 410px) {
        flex-direction: column;
      }

      .actions-buttons {
        height: 32px;
        padding: 4px 12px;
      }
    }
  }
}

.dark-background {
  background-color: rgba(0, 0, 0, 0.5);
}

.button-background {
  background-color: #39af49 !important;
}
</style>
