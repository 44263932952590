<template>
  <v-container class="py-0 py-sm-3">
    <loader v-if="is_loading"></loader>
    <div v-else-if="hasPlanting">
      <v-list>
        <v-list-item
          class="d-block d-sm-flex"
          v-for="semeadura in plantings"
          :key="semeadura.id"
        >
          <v-container>
            <v-row no-gutters>
              <v-col cols="12" sm="12">
                <v-row no-gutters>
                  <v-col cols="3" sm="6" class="text-left">
                    <span class="crop_type">
                      <v-img
                        :src="getCropIcon(semeadura.crop_id)"
                        class="icon-image"
                      ></v-img>
                      {{
                        $t(
                          `Plot.Crop.${getCropName(semeadura.crop_id)}`
                        ).toUpperCase()
                      }}
                    </span>
                    <br />
                    <span class="genetic_name">
                      {{ semeadura.cultivar }}
                    </span>
                  </v-col>
                  <br />
                  <v-col
                    v-if="semeadura.data_ideal_proxima_aplicacao"
                    cols="9"
                    sm="6"
                    class="text-left"
                  >
                    <span>
                      <p class="sown_title">
                        <v-icon size="12" color="#aab2a9">mdi-calendar</v-icon>
                        {{ $t('Plot.Planting.will_harvest_in').toUpperCase() }}
                        <br />
                      </p>
                      <p class="sown_data">
                        {{
                          semeadura.data_ideal_proxima_aplicacao
                            ? semeadura.data_ideal_proxima_aplicacao.formatDate()
                            : $t('Plot.Planting.no_prediction').toUpperCase()
                        }}
                      </p>
                    </span>
                  </v-col>
                </v-row>
                <v-row no-gutters class="margin">
                  <v-col cols="3" sm="6" class="text-left">
                    <span>
                      <p class="sown_title">
                        <v-icon size="12" color="#aab2a9"
                          >mdi-vector-square</v-icon
                        >
                        {{ $t('Plot.Planting.sown_area').toUpperCase() }} <br />
                      </p>
                      <p class="sown_data">
                        {{ area(semeadura.area) }}
                        {{ $unitMeasures['area'][$currentLanguage()] }}
                      </p>
                    </span>
                  </v-col>
                  <v-col cols="9" sm="6" class="text-left">
                    <span>
                      <p class="sown_title">
                        <v-icon size="12" color="#aab2a9">mdi-calendar</v-icon>
                        {{ $t('Plot.Planting.sown_in').toUpperCase() }} <br />
                      </p>
                      <p class="sown_data">
                        {{ semeadura.data_semeadura.formatDate() }}
                      </p>
                    </span>
                  </v-col>
                </v-row>
                <v-row no-gutters class="margin">
                  <v-col cols="3" sm="6" class="text-left">
                    <router-link
                      :to="'/execucao?semeadura_id=' + semeadura.id"
                      @click="registerEvent"
                    >
                      <span class="view_more">
                        {{ $t('Plot.Planting.view_more') }}
                      </span>
                      <font-awesome-icon
                        class="arrow-right"
                        icon="arrow-right"
                      />
                    </router-link>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-list-item>
        <v-spacer></v-spacer>
      </v-list>
    </div>
    <div v-else class="warning_title">
      {{ $t('Plot.Warnings.planting') }}
    </div>
  </v-container>
</template>

<script>
import Loader from '@/components/Loaders/Loader.vue'
import { mapActions, mapGetters } from 'vuex'
import { convertToTargetUnit } from '@/components/Form/converter'
import { logEvent, events } from '@/services/analytics'
import { getCropNameById, getCropIconById } from '@/utils/crops.js'

export default {
  name: 'PlantingTab',

  components: {
    Loader,
  },

  props: {
    plotId: {
      type: String,
      default: null,
    },
  },

  async created() {
    await this.loadSowingsFilter()
    this.sumSownArea()
  },

  watch: {
    plotId(old, val) {
      if (old != val) {
        this.sumSownArea()
      }
    },
  },

  computed: {
    ...mapGetters('semeaduras', ['semeaduras', 'is_loading']),
    hasPlanting() {
      return this.plantings.length > 0
    },
    plantings() {
      return this.semeaduras.filter(
        (semeadura) => semeadura.talhao_id == this.plotId
      )
    },
  },

  methods: {
    ...mapActions('semeaduras', ['loadSowingsFilter']),
    area(area) {
      return convertToTargetUnit(area, 'area')
    },
    sumSownArea() {
      const areaTotal = this.plantings.reduce(
        (total, semeadura) => total + parseFloat(semeadura.area),
        0
      )
      const fixedAreaTotal = Number.isInteger(areaTotal)
        ? areaTotal
        : parseFloat(areaTotal.toFixed(2))
      this.$emit('areaTotal', fixedAreaTotal)

      return fixedAreaTotal
    },
    getCropName(cropId) {
      return getCropNameById(cropId)
    },
    getCropIcon(cropId) {
      return getCropIconById(cropId)
    },
    registerEvent() {
      logEvent(events.plotModule.visitSeedlingPage)
    },
  },
}
</script>

<style scoped>
.crop_type {
  font-family: Source Sans Pro;
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #aab2a9;
}

.genetic_name {
  font-family: Rubik;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #0d0d0d;
}

.sown_title {
  font-family: Source Sans Pro;
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #aab2a9;
}

.sown_data {
  font-family: Rubik;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #5b6459;
}

.view_more {
  font-family: Source Sans Pro;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #1a2b46;
}

.margin {
  margin-top: 10px;
}

.arrow-right {
  font-size: 12px;
  font-weight: 900;
  line-height: 10px;
  text-align: center;
  color: #c3d1e9 !important;
}

.icon-image {
  display: inline-flex;
  align-items: center;
  width: 12px;
}

.warning_title {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  color: #1a2b46;
}
</style>
