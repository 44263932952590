<template>
  <v-container class="pa-0">
    <v-card class="ndvi-navigation">
      <v-tabs show-arrows>
        <v-tab
          v-for="(date, indexDate) in convertDates"
          :key="indexDate"
          @click="selectTabByClick(indexDate)"
        >
          {{ date.date }}
        </v-tab>
      </v-tabs>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'NdviNavigation',

  props: {
    images: { type: Array, required: true },
  },

  computed: {
    convertDates() {
      const formattedData = this.images.map((item) => {
        return {
          ...item,
          date: item.date.formatDate(),
        }
      })
      return formattedData
    },
  },

  methods: {
    selectTab(indexDate) {
      const propsData = this.images[indexDate]?.images
      this.$emit('date-selected', propsData)
    },
    selectTabByClick(indexDate) {
      this.selectTab(indexDate)
    },
  },
}
</script>

<style scoped>
.date {
  font-family: Source Sans Pro;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}

.label {
  font-family: Source Sans Pro;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  margin-right: 10px;
  color: #ffffff;
}

::v-deep .theme--light.v-tabs > .v-tabs-bar {
  background: #00000090 !important;
}

::v-deep .theme--light.v-tabs .v-tab {
  color: #ffffff !important;
}

::v-deep .theme--light.v-tabs .v-tab--active {
  background-color: transparent !important;
  color: #ffffff;
}

.theme--light.v-sheet {
  background-color: transparent !important;
}

::v-deep .v-slide-group__wrapper {
  color: #ffffff !important;
}
</style>

<style lang="scss">
.ndvi-navigation {
  .v-slide-group__prev,
  .v-slide-group__next {
    @media (max-width: 960px) {
      width: 28px;
      min-width: 28px;
    }

    .v-icon {
      color: white;
    }
  }
}
</style>
