<template>
  <v-card class="category-tabs__wrapper">
    <v-tabs
      v-model="tab"
      class="category-tabs"
      color="#39AF49"
      background-color="transparent"
      show-arrows
    >
      <v-tab v-for="category in categories" :key="category.id">
        <font-awesome-icon class="mr-2" :icon="category.icon" />
        <span>{{ $t(category.label) }}</span>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="category in categories" :key="category.id">
        <v-card flat>
          <v-card-text>
            <slot :name="category.name"></slot>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
export default {
  name: 'PlotCategoryTabs',

  data() {
    return {
      tab: null,
      categories: [
        {
          id: 1,
          name: 'planting',
          label: 'Plot.Categories.planting',
          icon: 'seedling',
        },
        {
          id: 2,
          name: 'notes',
          label: 'Plot.Categories.notes',
          icon: 'sticky-note',
        },
        {
          id: 3,
          name: 'weather',
          label: 'Plot.Categories.weather',
          icon: 'cloud',
        },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
.category-tabs__wrapper {
  min-height: 100%;
  max-height: 0px;
  max-width: 100%;
  overflow-y: auto;
  border-radius: 8px;
}

::v-deep .v-tab--active {
  color: #39af49 !important;
  font-weight: 700;
  font-size: 14px;

  @media (max-width: 375px) {
    font-size: 10px;
  }
}

.category-tabs {
  font-family: 'Rubik';
  font-size: 14px;
  border: 1px solid #e6e9e6;
  background: #fff;
  box-shadow: 2px 6px 9px 0px rgba(0, 0, 0, 0.05);
  padding: 0px 20px;

  @media (max-width: 1440px) {
    padding: 0px;
  }

  @media (max-width: 375px) {
    span {
      font-size: 10px;
    }
  }
}

@media screen and (max-width: 576px) {
  ::v-deep .v-item-group .v-slide-group__wrapper {
    display: -webkit-box !important;
  }
}
::v-deep .v-slide-group__prev {
  @media (max-width: 960px) {
    width: 28px;
    min-width: 28px;
  }
}
::v-deep .v-slide-group__next {
  @media (max-width: 960px) {
    width: 28px;
    min-width: 28px;
  }
}
::v-deep .v-tab {
  text-transform: none !important;
}
</style>
