<template>
  <v-container class="upsell pa-0">
    <div>
      <div class="mr-2 upgrade-text upgrade-background">
        {{ $t('Plot.Upsell.upgrade') }}
      </div>
      <br />
      <v-img :src="upsellLogoLastPlan" class="upsell-image" />
      <br />
      <div class="mr-2 body-text body-background">
        {{ $t('Plot.Upsell.message') }}
        <br />
        <v-btn
          class="white--text text-none button-register-confirm"
          text
          @click="
            redirectLink(
              'https://wa.me/5551994567668?text=',
              $t('Plot.NdviUpsell.contract_ndvi')
            )
          "
        >
          <div class="mr-2">{{ $t('Plot.Upsell.button') }}</div>
          <font-awesome-icon class="icons" icon="external-link-alt" />
        </v-btn>
      </div>
    </div>
  </v-container>
</template>

<script>
import PlanMixin from '@/components/PlanMixin.vue'
import { logEvent, events } from '@/services/analytics'

export default {
  name: 'Upsell',

  mixins: [PlanMixin],

  async created() {
    await this.getLastUpsellPlan()
  },

  methods: {
    redirectLink(link, message) {
      logEvent(events.plotModule.upgradeToFull)
      const translatedMessage = '+' + message.replaceAll(' ', '+')
      window.open(link + translatedMessage, '_blank')
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  &.upsell {
    max-width: 280px;
  }
}

.upgrade-background {
  width: 140px !important;
  height: 22px !important;
  padding: 4px, 8px, 4px, 8px;
  border-radius: 50px;
  background: linear-gradient(90deg, #39af49 0%, #2ede46 40%, #f2cd12 100%);
}

.upgrade-text {
  font-family: Rubik;
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  color: #ffffff;
}

.body-background {
  text-align: left;
}

.body-text {
  font-family: Source Sans Pro;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  color: #6f6c90;
}

.button-register-confirm {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0;
  margin-top: 10px;
  background-color: #39af49 !important;
  border-color: #39af49;
}

.upsell-image {
  width: 200px;
}
</style>
