<template>
  <v-container class="ndvi-map pa-0">
    <v-overlay v-if="emptyImages" :absolute="true" :z-index="6">
      <v-btn color="white" disabled>
        {{ $t('select_another_period') }}
      </v-btn>
    </v-overlay>
    <v-card v-if="tabItems.length > 0" flat class="map__wrapper">
      <ndvi-map
        class="map"
        v-for="(item, index) in tabItems"
        :key="index"
        :image-url="item.value"
        :latitude="latitude"
        :longitude="longitude"
        :bounds="bounds"
      >
      </ndvi-map>
    </v-card>
  </v-container>
</template>

<script>
import NdviMap from '@/pages/ndvi/NDVIMap.vue'

export default {
  components: { NdviMap },

  props: {
    emptyImages: {
      type: Boolean,
      required: true,
    },
    tabItems: {
      type: Array,
      required: true,
    },
    longitude: { type: Number, required: true },
    latitude: { type: Number, required: true },
    bounds: { type: Array, required: true },
  },
}
</script>

<style lang="scss" scoped>
.ndvi-map {
  position: relative;
  width: auto;
  height: 100%;

  .map__wrapper {
    height: 100%;
  }

  .map {
    height: 100% !important;
    z-index: 1;
  }
}
</style>
