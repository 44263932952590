<template>
  <v-container class="note-tab pa-0">
    <loader v-if="loading" />
    <div v-else-if="hasNotes">
      <v-list
        class="d-flex flex-column note-tab__list"
        subheader
        three-line
        v-for="(dayGroup, i) in notesGroupedByDay"
        :key="i"
      >
        <v-subheader class="list__title color-neutral-1 text-none px-0">
          {{ dayGroup.day }}
        </v-subheader>
        <v-list-item
          class="list__item"
          v-for="note in dayGroup.notes"
          :key="note.id"
          @click="redirectToNotes"
        >
          <v-card width="100%" outlined>
            <v-card-text>
              <v-row no-gutters>
                <v-col class="text-left">
                  <span class="item__date color-neutral-0">
                    {{ note.created_at }} /
                  </span>
                  <font-awesome-icon
                    class="personalized-icon item__category"
                    :icon="iconCategory(note.category.name)"
                  />
                  <span
                    class="item__category text-uppercase color-secondary-light-1 px-1"
                  >
                    {{ $t('Plot.Notes.categories.' + note.category.name) }}
                  </span>
                </v-col>
              </v-row>
              <v-row no-gutters class="pt-2 text-left">
                <v-spacer class="d-none"></v-spacer>
                <v-col cols="12" sm="8" md="9">
                  <span class="color-neutral-m-3 item__title">
                    {{ note.title }}
                  </span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-list-item>
      </v-list>
    </div>
    <div v-else class="item__title">
      {{ $t('Plot.Warnings.note') }}
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Loader from '@/components/Loaders/Loader.vue'

import {
  formatDayOfMonth,
  formatDayDayOfMonth,
  formatHour,
} from '@/utils/formatters'

const CATEGORIES_ICON = {
  buy_and_sell: 'dollar-sign',
  climate: 'cloud-sun-rain',
  diseases: 'disease',
  fertilizing: 'heart',
  machines: 'tractor',
  nematodes: 'bacterium',
  others: 'file',
  pests: 'bug',
}

export default {
  name: 'NoteTab',

  components: {
    Loader,
  },

  props: {
    plotId: {
      type: String,
      default: null,
    },
  },

  async created() {
    await this.fetchNotes({ farmId: this.currentFarmId })
  },

  computed: {
    ...mapGetters('farms', ['currentFarmId']),
    ...mapGetters('notes', ['notes', 'loading']),
    hasNotes() {
      return this.notesData.length > 0
    },
    notesData() {
      return this.notes.filter((note) => note.plot.id == this.plotId)
    },
    notesGroupedByDay() {
      const days = {}

      this.notesData.forEach((note) => {
        const date = note.created_at.formatDate()
        const day = this.$vuetify.breakpoint.xs
          ? formatDayOfMonth(note.created_at)
          : formatDayDayOfMonth(note.created_at)
        const time = formatHour(note.created_at)

        if (!days[date]) {
          days[date] = { day, notes: [] }
        }

        days[date].notes.push({ ...note, created_at: time })
      })

      return Object.values(days)
    },
  },

  methods: {
    ...mapActions('notes', ['fetchNotes']),
    iconCategory(category) {
      return CATEGORIES_ICON[category]
    },
    redirectToNotes() {
      this.$router.push({ path: '/notes' })
    },
  },
}
</script>

<style lang="scss" scoped>
.note-tab {
  .note-tab__list {
    gap: 12px;

    .list__title {
      font-family: 'Rubik' !important;
      font-style: normal !important;
      font-weight: 500 !important;
      font-size: 14px !important;
      line-height: 18px !important;
      color: #788476 !important;
    }
    .list__item {
      padding: 0px;

      .item__date {
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #788476;
      }
      .item__category {
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 18px;
        color: #4a76bc;
      }
      .item__title {
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 21px;
        color: #1a2b46;
      }
    }
  }
}
</style>
